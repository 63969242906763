<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div>
        <div class="pa-4">
          <div class="d-flex justify-space-between align-center mb-4">
            <v-btn
              v-if="solicitacaoCriarParaTreinamentos"
              small
              depressed
              color="fmq_gray"
              dark
              outlined
              to="/treinamento"
            >
              <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
            >
          </div>
          <SolicitacoesTreinamentoFormulario
            :loadingBtn="loadingBtn"
            :listRepresentantes="listRepresentantes"
            :listProdutos="listProdutos"
            :listAssuntos="listAssuntos"
            :item="solicitacao"
            @send="editar"
          />
        </div>
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Solicitação editada com sucesso"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import SolicitacoesTreinamentoFormulario from "@/components/solicitacoes/SolicitacoesTreinamentoFormulario";
import {
  editarTreinamentoSolicitacao,
  exibirSolicitacao,
} from "@/services/solicitacoes";
import { listarProdutos } from "@/services/produtos";
import { listarRepresentantes } from "@/services/user";
export default {
  name: "SolicitacoesTreinamentoEditar",
  components: { SolicitacoesTreinamentoFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Lista de Treinamentos",
        disabled: false,
        to: "/treinamentos",
      },
      {
        text: "Editar Solicitação de Treinameto",
        disabled: true,
        to: "",
      },
    ],
    solicitacao: {},
    error: false,
    success: false,
    loadingBtn: false,
    loading: false,
    messageError: null,
    listProdutos: [],
    listAssuntos: [
      { name: "Anatomia/Fisiologia", value: "Anatomia/Fisiologia" },
      { name: "Patologia", value: "Patologia" },
      { name: "Produto", value: "Produto" },
      { name: "Concorrência", value: "Concorrência" },
      { name: "BVC", value: "BVC" },
      { name: "P&A", value: "P&A" },
      { name: "Material promocional", value: "Material promocional" },
      { name: "Outros", value: "Outros" },
    ],
    listRepresentantes: [],
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getProdutos();
    this.getRepresentantes();
    this.exibir()
  },
  methods: {
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirSolicitacao(this.$route.params.id);
        this.solicitacao = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarTreinamentoSolicitacao(this.$route.params.id, event).then(
          () => {
            this.success = true;
          }
        );
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getRepresentantes() {
      const resp = await listarRepresentantes();
      this.listRepresentantes = resp.data;
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "SolicitacoesTreinamento" });
    },
  },
  computed: {
    solicitacaoCriarParaTreinamentos() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.criarParaTreinamentos"
      );
    },
  },
};
</script>

<style></style>
